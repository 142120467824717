import openseaIcon from "../images/icons/socials/opensea.svg";
import instagramIcon from "../images/icons/socials/instagram.svg";
import twitterIcon from "../images/icons/socials/twitter.svg";

const Footer = () => {
  function openTiles() {
    const tilesPopup = document.getElementById("popup-tiles");
    tilesPopup.classList.add("_popup-open");
    document.documentElement.classList.add("_popup-open-purple");
  }
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__copy">
          © 2023 ALGO built by <a href="https://ravaga.com">ravaga</a> with{" "}
          <a href="https://www.alchemy.com/">alchemy</a> on{" "}
          <a href="https://polygon.technology/">polygon</a>
        </div>
        <button
          type="button"
          className="footer__arrow"
          onClick={() => openTiles()}
        ></button>
        <ul className="footer__socials socials-footer">
          <li>
            <a
              href="https://opensea.io/collection/algo-by-casey-milone"
              className="socials-footer__link"
            >
              <img src={openseaIcon} alt="opensea icon" />
            </a>
          </li>
          {/* <li>
            <a href="" className="socials-footer__link">
              <img src={instagramIcon} alt="instagram icon" />
            </a>
          </li> */}
          <li>
            <a
              href="https://twitter.com/CaseyMilone"
              className="socials-footer__link"
            >
              <img src={twitterIcon} alt="twitter icon" />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
