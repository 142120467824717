import { useContext } from "react";
import { claimPrize } from "../ethereum/index";
import { Store } from "../store/store-reducer";
import { pdfFilesStorage } from "../store/pdf-files";
import {
  updateBookAction,
  updateBookClaimAction,
  updateTokenDataAction,
} from "../store/actions";
import { getNfts } from "../ethereum";
import crossIcon from "../images/icons/cross.svg";
import noBookImage from "../images/no-book.png";
import starIcon from "../images/icons/star.svg";
import bookVideo from "../files/videos/algo-book.webm";

const SmallPopup = (props) => {
  const { dispatch, state } = useContext(Store);

  function oderBook() {
    alert("Making order for the book");
  }

  const updateStats = async (address) => {
    const tokenList = await getNfts(address);
    const tokenData = state.tokenData;
    for (let i = 0; i < tokenData.length; i++) {
      const token = tokenList.find(
        (token) => tokenData[i].tokenId === +token.tokenId
      );
      if (token) {
        tokenData[i].balance = token.balance;
      }
    }
    updateTokenDataAction(dispatch, tokenData);
    updateBookAction(dispatch, true);
    updateBookClaimAction(dispatch, true);
  };

  const mintBook = async () => {
    props.setTransactionStatus(true);
    await claimPrize(state.wallet.signer).then(() => {
      props.setTransactionStatus(false);
      updateStats(state.wallet.address);
    });
  };

  const Actions = (button) => {
    return (
      <>
        <div className="popup-actions">
          <div className="popup-actions__buttons">
            <button
              className="popup-actions__button"
              onClick={() => props.flipSmallPopup()}
            >
              <img src={crossIcon} alt="cross icon" />
            </button>
            {state.algoBookClaimed && state.algoBook && (
              <button
                onClick={() => oderBook()}
                className="popup-actions__button popup-actions__button_shadows disabled"
              >
                <div className="popup-actions__quantity">Soon</div>
                <img src={starIcon} alt="star icon" />
              </button>
            )}
          </div>
        </div>
      </>
    );
  };

  const Content = () => {
    const NotAllTokens = () => {
      return (
        <div className="small-popup">
          <div className="small-popup__image-ibg">
            <video autoPlay loop src={bookVideo}></video>
          </div>
          <div className="small-popup__title">
            You have {props.availableTokens} of {props.allTokens} chapters
          </div>
          <div className="small-popup__text">
            Collect the rest of the chapters to claim the whole book
          </div>
          <button
            onClick={() => props.flipSmallPopup()}
            type="button"
            className="small-popup__button button button_border"
          >
            <span>Got it!</span>
          </button>
        </div>
      );
    };

    const ReadyToMint = () => {
      return (
        <div className="small-popup">
          <div className="small-popup__image-ibg">
            <video autoPlay loop src={bookVideo}></video>
          </div>
          <div className="small-popup__title">Congratulations!</div>
          <div className="small-popup__text">
            You have collected all the chapters and can claim the book. One
            piece of each chapter will be burned on claim!
          </div>
          <button
            onClick={() => mintBook()}
            type="button"
            className="small-popup__button button button_border"
          >
            <span>Mint ALGO</span>
          </button>
        </div>
      );
    };

    const BookNotFound = () => {
      return (
        <div className="small-popup">
          <div className="small-popup__image-ibg duplicate__image-ibg_contain">
            <img src={noBookImage} alt="no book image" />
          </div>
          <div className="small-popup__title">Book is claimed!</div>
          <div className="small-popup__text">
            But we can’t find it in your wallet :(
          </div>
          <button
            onClick={() => props.flipSmallPopup()}
            type="button"
            className="small-popup__button button button_border"
          >
            <span>Got it!</span>
          </button>
        </div>
      );
    };

    const WholeBook = () => {
      return (
        <div className="small-popup">
          <div className="small-popup__image-ibg">
            <video autoPlay loop src={bookVideo}></video>
          </div>
          <div className="small-popup__title">Congratulations!</div>
          <div className="small-popup__text">Now you have a complete book!</div>
          <a
            href={pdfFilesStorage.fullBook}
            download
            className="small-popup__button button button_border"
          >
            <span>Download ALGO</span>
          </a>
        </div>
      );
    };

    const isAllTokens = props.availableTokens === props.allTokens;
    const IsBookClaimedActions = () =>
      state.algoBookClaimed ? <WholeBook /> : <BookNotFound />;
    const AllTokensActions = () =>
      state.algoBook ? <IsBookClaimedActions /> : <ReadyToMint />;

    return <>{isAllTokens ? <AllTokensActions /> : <NotAllTokens />}</>;
  };

  return (
    <>
      <div id="popup-order" className="popup">
        <div className="popup__wrapper">
          <div className="popup__content">
            <Content />
            <Actions />
          </div>
        </div>
      </div>
    </>
  );
};

export default SmallPopup;
