import { useContext, useState } from "react";

// Components
import { Store } from "../store/store-reducer";
import Tile from "./Tile";
import ChapterPopup from "./ChapterPopup";
import SmallPopup from "./SmallPopup";

// Images
import crossIcon from "../images/icons/cross.svg";
import bookIcon from "../images/icons/book.svg";

const Tiles = (props) => {
  const { state } = useContext(Store);
  const [chapterOpen, setChapterOpen] = useState(false);
  const [smallPopupOpen, setSmallPopupOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const [transactionStatus, setTransactionStatus] = useState(false);

  const flipSmallPopup = () => {
    if (smallPopupOpen) {
      // Close
      setSmallPopupOpen(false);
      // Remove class from opened popup
      document.getElementById("popup-order").classList.remove("_popup-open");
    } else {
      // Open
      setSmallPopupOpen(true);
      // Add class to selected popup
      setTimeout(() => {
        document.getElementById("popup-order").classList.add("_popup-open");
      }, 0);
    }
  };

  const flipChapter = (tokenId) => {
    setSelected(tokenId);
    if (chapterOpen) {
      // Close chapter
      setChapterOpen(false);
      // Remove class from opened popup
      document.getElementById("popup-chapter").classList.remove("_popup-open");
      // Remove class from HTML tag
      document.documentElement.classList.remove("_popup-open");
    } else {
      // Open chapter
      setChapterOpen(true);
      // Add class to selected popup
      setTimeout(() => {
        document.getElementById("popup-chapter").classList.add("_popup-open");
      }, 0);
      // Remove class from HTML tag
      document.documentElement.classList.add("_popup-open");
    }
  };

  const closeTiles = () => {
    document.documentElement.classList.remove("_popup-open-purple");
    document.getElementById("popup-tiles").classList.remove("_popup-open");
  };

  const getAvailableTokens = () => {
    let count = 0;
    state.tokenData.forEach((el) => {
      if (el.balance > 0) {
        count++;
      }
    });
    return count;
  };

  return (
    <>
      {transactionStatus
        ? document.documentElement.classList.add("_transaction")
        : document.documentElement.classList.remove("_transaction")}
        
      {chapterOpen && (
        <ChapterPopup
          tokenId={selected}
          flipChapter={flipChapter}
          flipSmallPopup={flipSmallPopup}
          setSelected={setSelected}
          chapter={state.tokenData.find((token) => token.tokenId === selected).chapter}
        />
      )}

      {smallPopupOpen && (
        <SmallPopup
          flipSmallPopup={flipSmallPopup}
          transactionStatus={transactionStatus}
          setTransactionStatus={setTransactionStatus}
          availableTokens={getAvailableTokens()}
          allTokens={state.tokenData.length}
        />
      )}
      <div id="popup-tiles" className="popup">
        <div className="popup__wrapper">
          <div className="popup__content">
            <div className="token-tiles">
              <div className="token-tiles__items">
                {state.tokenData.map((token) => (
                  <Tile
                    key={token.tokenId}
                    tokenId={token.tokenId}
                    chapter={token.chapter}
                    title={token.name}
                    img={token.image}
                    balance={token.balance}
                    url={token.url}
                    flipChapter={flipChapter}
                  />
                ))}
              </div>
            </div>
            <div className="popup-actions">
              <div className="popup-actions__buttons">
                <button onClick={() => closeTiles()} className="popup-actions__button">
                  <img src={crossIcon} alt="cross icon" />
                </button>
                <button
                  onClick={() => flipSmallPopup()}
                  className="popup-actions__button popup-actions__button_shadows"
                >
                  <div className="popup-actions__quantity">
                    <span>{getAvailableTokens()}</span>/<span>{state.tokenData.length}</span>
                  </div>
                  <img src={bookIcon} alt="book icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tiles;
