import { useContext, useState } from "react";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import { polygon } from "wagmi/chains";
import { InjectedConnector } from "wagmi/connectors/injected";
import { Store } from "../store/store-reducer";
import {
  updateWalletAction,
  updateTokenDataAction,
  updateBookAction,
  updateBookClaimAction,
} from "../store/actions";
import { getNfts, checkClaim } from "../ethereum";

const WalletButton = () => {
  const { state, dispatch } = useContext(Store);
  const { chain } = useNetwork();
  const [loading, setLoading] = useState(false);
  const { switchNetworkAsync } = useSwitchNetwork();
  const { connect } = useConnect({
    chainId: polygon.id,
    connector: new InjectedConnector(),
  });

  const { disconnect } = useDisconnect();

  const updateStats = async (address, connector) => {
    setLoading(true);
    const signer = await connector.getSigner();
    const tokenList = await getNfts(address);
    const isClaimed = await checkClaim(signer, address);
    const tokenData = state.tokenData;
    const bookBalance = tokenList.find((token) => token.tokenId === "0");
    if (bookBalance && bookBalance.balance > 0) {
      updateBookAction(dispatch, true);
    }
    if (isClaimed) {
      updateBookClaimAction(dispatch, true);
    }
    for (let i = 0; i < tokenData.length; i++) {
      const token = tokenList.find(
        (token) => tokenData[i].tokenId === +token.tokenId
      );
      if (token) {
        tokenData[i].balance = token.balance;
      }
    }
    updateWalletAction(dispatch, {
      address,
      signer,
    });
    updateTokenDataAction(dispatch, tokenData);
    setLoading(false);
  };

  const checkMetamask = () => {
    if (typeof window.ethereum === "undefined") {
      const headerButton = document.querySelector(".header__button");
      headerButton.classList.add("shake");
      setTimeout(() => {
        headerButton.classList.remove("shake");
      }, 150);
    }
  };

  // The function that cuts the center of the wallet number
  function start_and_end(str) {
    if (str.length > 20) {
      return str.substr(0, 7) + "..." + str.substr(str.length - 4, str.length);
    }
    return str;
  }

  const switchChain = async () => {
    await switchNetworkAsync(polygon.id);
  };

  const account = useAccount({
    onConnect({ address, connector, isReconnected }) {
      updateStats(address, connector);
    },
  });

  if (account.isConnected) {
    return chain && chain.id === 137 ? (
      <button
        className="header__button button-shadow"
        type="button"
        onClick={() => disconnect()}
      >
        <span className="header__address">
          {start_and_end(account.address)}
        </span>
        <span className="header__disconnect">Disconnect</span>
      </button>
    ) : (
      <button
        className="header__button button-shadow"
        type="button"
        onClick={() => switchChain()}
      >
        <span className="header__address">Switch to polygon</span>
        <span className="header__disconnect">Switch to polygon</span>
      </button>
    );
  }
  return (
    <>
      {loading
        ? document.documentElement.classList.add("_transaction")
        : document.documentElement.classList.remove("_transaction")}
      <button
        className="header__button button-shadow"
        type="button"
        onClick={() => (connect(), checkMetamask())}
      >
        <span>Connect Wallet</span>
      </button>
    </>
  );
};

export default WalletButton;
