import { ethers } from "ethers";
import { contractDetails } from "./config";

const getAlgoContract = (provider) => {
  return new ethers.Contract(
    contractDetails.address,
    contractDetails.abi,
    provider
  );
};

export const getCopies = async (provider, address, tokenId) => {
  const contract = getAlgoContract(provider);
  try {
    const response = await contract.userCopies(address, tokenId);
    return response;
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const makeCopy = async (provider, tokenId, amount = 1) => {
  const contract = getAlgoContract(provider);
  const tx = await contract.makeCopy(tokenId, amount, {
    value: ethers.utils.parseEther((amount * 0.1).toString()),
  });
  const receipt = await tx.wait();
  return receipt.transactionHash;
};

export const claimPrize = async (provider) => {
  const contract = getAlgoContract(provider);
  const tx = await contract.claimPrize();
  const receipt = await tx.wait();
  return receipt.transactionHash;
};

export const checkClaim = async (provider, address) => {
  const contract = getAlgoContract(provider);
  try {
    const response = await contract.bookClaimed(address);
    return response;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getNfts = async (address) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  var urlencoded = new URLSearchParams();
  urlencoded.append("address", address);

  const response = await fetch("https://algo.ravaga.com/api/getNfts", {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
  });
  const data = await response.json();
  return data;
};
