import tileBg1 from "../images/tiles/01.jpg";
import tileBg2 from "../images/tiles/02.jpg";
import tileBg3 from "../images/tiles/03.jpg";
import tileBg4 from "../images/tiles/04.jpg";
import tileBg5 from "../images/tiles/05.jpg";
import tileBg6 from "../images/tiles/06.jpg";
import tileBg7 from "../images/tiles/07.jpg";
import tileBg8 from "../images/tiles/08.jpg";
import tileBg9 from "../images/tiles/09.jpg";
import tileBg10 from "../images/tiles/10.jpg";
import tileBg11 from "../images/tiles/11.jpg";
import tileBg12 from "../images/tiles/12.jpg";
import tileBg13 from "../images/tiles/13.jpg";
import tileBg14 from "../images/tiles/14.jpg";
import tileBg15 from "../images/tiles/15.jpg";
import tileBg16 from "../images/tiles/16.jpg";
import tileBg17 from "../images/tiles/17.jpg";
import tileBg18 from "../images/tiles/18.jpg";
import tileBg19 from "../images/tiles/19.jpg";
import tileBg20 from "../images/tiles/20.jpg";
import tileBg21 from "../images/tiles/14.jpg";
import tileBg22 from "../images/tiles/22.jpg";
import tileBg23 from "../images/tiles/23.jpg";
import tileBg24 from "../images/tiles/24.jpg";
import tileBg25 from "../images/tiles/25.jpg";
import tileBg26 from "../images/tiles/26.jpg";
import tileBg27 from "../images/tiles/27.jpg";
import tileBg28 from "../images/tiles/28.jpg";
import { contractDetails } from "../ethereum/config";
import { createContext, useReducer } from "react";

const getUrl = (id) =>
  `https://opensea.io/assets/${contractDetails.network}/${contractDetails.address}/${id}`;

const initialState = {
  wallet: {},
  algoBook: false,
  algoBookClaimed: false,
  tokenData: [
    {
      tokenId: 1,
      chapter: "Chapter 1",
      name: "International District",
      image: tileBg1,
      balance: 0,
      url: getUrl(1),
    },
    {
      tokenId: 2,
      chapter: "Chapter 2",
      name: "Downtown",
      image: tileBg2,
      balance: 0,
      url: getUrl(2),
    },
    {
      tokenId: 3,
      chapter: "Chapter 3",
      name: "Capital Hill",
      image: tileBg3,
      balance: 0,
      url: getUrl(3),
    },
    {
      tokenId: 4,
      chapter: "Chapter 4",
      name: "East Lake",
      image: tileBg4,
      balance: 0,
      url: getUrl(4),
    },
    {
      tokenId: 5,
      chapter: "Chapter 5",
      name: "Moshi Moshi Creative",
      image: tileBg5,
      balance: 0,
      url: getUrl(5),
    },
    {
      tokenId: 6,
      chapter: "Chapter 6",
      name: "Cherry Hill",
      image: tileBg6,
      balance: 0,
      url: getUrl(6),
    },
    {
      tokenId: 7,
      chapter: "Chapter 7",
      name: "Southcenter Mall",
      image: tileBg7,
      balance: 0,
      url: getUrl(7),
    },
    {
      tokenId: 8,
      chapter: "Chapter 8",
      name: "Moshi Moshi Boardroom",
      image: tileBg8,
      balance: 0,
      url: getUrl(8),
    },
    {
      tokenId: 9,
      chapter: "Chapter 9",
      name: "Pioner Square Tunnel",
      image: tileBg9,
      balance: 0,
      url: getUrl(9),
    },
    {
      tokenId: 10,
      chapter: "Chapter 10",
      name: "Vactube",
      image: tileBg10,
      balance: 0,
      url: getUrl(10),
    },
    {
      tokenId: 11,
      chapter: "Chapter 11",
      name: "Soma Hotel",
      image: tileBg11,
      balance: 0,
      url: getUrl(11),
    },
    {
      tokenId: 12,
      chapter: "Chapter 12",
      name: "Embarcadero",
      image: tileBg12,
      balance: 0,
      url: getUrl(12),
    },
    {
      tokenId: 13,
      chapter: "Chapter 13",
      name: "Noodle Bus",
      image: tileBg13,
      balance: 0,
      url: getUrl(13),
    },
    {
      tokenId: 14,
      chapter: "Chapter 14",
      name: "Soma Rooftop",
      image: tileBg14,
      balance: 0,
      url: getUrl(14),
    },
    {
      tokenId: 15,
      chapter: "Chapter 15",
      name: "Highway 80",
      image: tileBg15,
      balance: 0,
      url: getUrl(15),
    },
    {
      tokenId: 16,
      chapter: "Chapter 16",
      name: "Downtown Reno",
      image: tileBg16,
      balance: 0,
      url: getUrl(16),
    },
    {
      tokenId: 17,
      chapter: "Chapter 17",
      name: "Cabaret",
      image: tileBg17,
      balance: 0,
      url: getUrl(17),
    },
    {
      tokenId: 18,
      chapter: "Chapter 18",
      name: "Highway 580",
      image: tileBg18,
      balance: 0,
      url: getUrl(18),
    },
    {
      tokenId: 19,
      chapter: "Chapter 19",
      name: "North Beach",
      image: tileBg19,
      balance: 0,
      url: getUrl(19),
    },
    {
      tokenId: 20,
      chapter: "Chapter 20",
      name: "First Hill Walk",
      image: tileBg20,
      balance: 0,
      url: getUrl(20),
    },
    {
      tokenId: 21,
      chapter: "Chapter 21",
      name: "Capital Hill Alleyway",
      image: tileBg21,
      balance: 0,
      url: getUrl(21),
    },
    {
      tokenId: 22,
      chapter: "Chapter 22",
      name: "Moshi Moshi Phone Room",
      image: tileBg22,
      balance: 0,
      url: getUrl(22),
    },
    {
      tokenId: 23,
      chapter: "Chapter 23",
      name: "Pioner Square Office",
      image: tileBg23,
      balance: 0,
      url: getUrl(23),
    },
    {
      tokenId: 24,
      chapter: "Chapter 24",
      name: "Internation District Station",
      image: tileBg24,
      balance: 0,
      url: getUrl(24),
    },
    {
      tokenId: 25,
      chapter: "Chapter 25",
      name: "Space Needle",
      image: tileBg25,
      balance: 0,
      url: getUrl(25),
    },
    {
      tokenId: 26,
      chapter: "Chapter 26",
      name: "Seattle Center",
      image: tileBg26,
      balance: 0,
      url: getUrl(26),
    },
    {
      tokenId: 27,
      chapter: "Chapter 27",
      name: "Westlake",
      image: tileBg27,
      balance: 0,
      url: getUrl(27),
    },
    {
      tokenId: 28,
      chapter: "Chapter 28",
      name: "Epilogue",
      image: tileBg28,
      balance: 0,
      url: getUrl(28),
    },
  ],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "WALLET_UPDATED":
      return { ...state, wallet: action.payload };
    case "TOKENDATA_UPDATED":
      return { ...state, tokenData: action.payload };
    case "BOOK_UPDATED":
      return { ...state, algoBook: action.payload };
    case "CLAIMED_UPDATED":
      return { ...state, algoBookClaimed: action.payload };
    default:
      return { ...state };
  }
};

export const Store = createContext(initialState);
export const StoreProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Store.Provider value={{ state, dispatch }}>
      {props.children}
    </Store.Provider>
  );
};
