import WalletButton from "./WalletButton";
import logo from "../images/logo.png";

const Header = () => {
  return (
    <header className="header">
      <div className="header__container">
        <a href="" className="header__logo"><img src={logo} alt="algo logo" /></a>
        <WalletButton />
      </div>
    </header>
  );
  
};

export default Header;
