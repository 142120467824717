import chapter_1 from "../files/pdf-files/chapter-1.pdf"
import chapter_2 from "../files/pdf-files/chapter-2.pdf"
import chapter_3 from "../files/pdf-files/chapter-3.pdf"
import chapter_4 from "../files/pdf-files/chapter-4.pdf"
import chapter_5 from "../files/pdf-files/chapter-5.pdf"
import chapter_6 from "../files/pdf-files/chapter-6.pdf"
import chapter_7 from "../files/pdf-files/chapter-7.pdf"
import chapter_8 from "../files/pdf-files/chapter-8.pdf"
import chapter_9 from "../files/pdf-files/chapter-9.pdf"
import chapter_10 from "../files/pdf-files/chapter-10.pdf"
import chapter_11 from "../files/pdf-files/chapter-11.pdf"
import chapter_12 from "../files/pdf-files/chapter-12.pdf"
import chapter_13 from "../files/pdf-files/chapter-13.pdf"
import chapter_14 from "../files/pdf-files/chapter-14.pdf"
import chapter_15 from "../files/pdf-files/chapter-15.pdf"
import chapter_16 from "../files/pdf-files/chapter-16.pdf"
import chapter_17 from "../files/pdf-files/chapter-17.pdf"
import chapter_18 from "../files/pdf-files/chapter-18.pdf"
import chapter_19 from "../files/pdf-files/chapter-19.pdf"
import chapter_20 from "../files/pdf-files/chapter-20.pdf"
import chapter_21 from "../files/pdf-files/chapter-21.pdf"
import chapter_22 from "../files/pdf-files/chapter-22.pdf"
import chapter_23 from "../files/pdf-files/chapter-23.pdf"
import chapter_24 from "../files/pdf-files/chapter-24.pdf"
import chapter_25 from "../files/pdf-files/chapter-25.pdf"
import chapter_26 from "../files/pdf-files/chapter-26.pdf"
import chapter_27 from "../files/pdf-files/chapter-27.pdf"
import chapter_28 from "../files/pdf-files/epilogue.pdf"

import fullBook from "../files/pdf-files/full-book.pdf"

export const pdfFilesStorage = {
  1: chapter_1,
  2: chapter_2,
  3: chapter_3,
  4: chapter_4,
  5: chapter_5,
  6: chapter_6,
  7: chapter_7,
  8: chapter_8,
  9: chapter_9,
  10: chapter_10,
  11: chapter_11,
  12: chapter_12,
  13: chapter_13,
  14: chapter_14,
  15: chapter_15,
  16: chapter_16,
  17: chapter_17,
  18: chapter_18,
  19: chapter_19,
  20: chapter_20,
  21: chapter_21,
  22: chapter_22,
  23: chapter_23,
  24: chapter_24,
  25: chapter_25,
  26: chapter_26,
  27: chapter_27,
  28: chapter_28,
  fullBook: fullBook,
};
