import preloaderIcon from "../images/icons/loading.svg";

const Preloader = () => {

  return (
    <div className="preloader">
      <img src={preloaderIcon} alt="preloader icon" />
    </div>
  );
};

export default Preloader;