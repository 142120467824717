export const updateWalletAction = (dispatch, wallet) => {
  return dispatch({
    type: "WALLET_UPDATED",
    payload: wallet,
  });
};

export const updateTokenDataAction = (dispatch, tokenData) => {
  return dispatch({
    type: "TOKENDATA_UPDATED",
    payload: tokenData,
  });
};

export const updateBookAction = (dispatch, book) => {
  return dispatch({
    type: "BOOK_UPDATED",
    payload: book,
  });
};

export const updateBookClaimAction = (dispatch, bookClaim) => {
  return dispatch({
    type: "CLAIMED_UPDATED",
    payload: bookClaim,
  });
};
