class MousePRLX {
	constructor(props, data = null) {
		let defaultConfig = {
			init: true,
			logging: true,
		};
		this.config = Object.assign(defaultConfig, props);
		if (this.config.init) {
			const paralaxMouse = document.querySelectorAll("[data-prlx-mouse]");

			if (paralaxMouse.length) {
				this.paralaxMouseInit(paralaxMouse);
			}
		}
	}
	paralaxMouseInit(paralaxMouse) {
		for (let index = 0; index < paralaxMouse.length; index++) {
			const el = paralaxMouse[index];
			const paralaxMouseWrapper = el.closest("[data-prlx-mouse-wrapper]");
			const paramСoefficientX = el.dataset.prlxCx ? +el.dataset.prlxCx : 60;
			const paramСoefficientY = el.dataset.prlxCy ? +el.dataset.prlxCy : 60;
			const paramAnimation = el.dataset.prlxA ? +el.dataset.prlxA : 100;

			let positionX = 0,
				positionY = 0;
			let coordXprocent = 0,
				coordYprocent = 0;

			setMouseParallaxStyle();

			if (paralaxMouseWrapper) {
				mouseMoveParalax(paralaxMouseWrapper);
			} else {
				mouseMoveParalax();
			}

			function setMouseParallaxStyle() {
				const distX = coordXprocent - positionX;
				const distY = coordYprocent - positionY;
				positionX = positionX + (distX * paramAnimation) / 1000;
				positionY = positionY + (distY * paramAnimation) / 1000;
				el.style.cssText = `transform: rotateY(${1 * positionX / (paramСoefficientX / 10)}deg) rotateX(${-1 * positionY / (paramСoefficientY / 10)}deg) translateY(${index === 1 ? '5%' : index === 2 ? '10%' : index === 3 ? '15%' : 0});`;
				requestAnimationFrame(setMouseParallaxStyle);
			}
			function mouseMoveParalax(wrapper = window) {
				wrapper.addEventListener("mousemove", function (e) {
					const offsetTop = el.getBoundingClientRect().top + window.scrollY;
					if (
						offsetTop >= window.scrollY ||
						offsetTop + el.offsetHeight >= window.scrollY
					) {
						const parallaxWidth = window.innerWidth;
						const parallaxHeight = window.innerHeight;
						const coordX = e.clientX - parallaxWidth / 2;
						const coordY = e.clientY - parallaxHeight / 2;
						coordXprocent = (coordX / parallaxWidth) * 100;
						coordYprocent = (coordY / parallaxHeight) * 100;
					}
				});
			}
		}
	}
}

setTimeout(() => {
	new MousePRLX({});
}, 100);
