import { useContext, useState, useEffect } from "react";
import { Store } from "../store/store-reducer";
import { updateTokenDataAction } from "../store/actions";
import crossIcon from "../images/icons/cross.svg";
import downloadIcon from "../images/icons/download.svg";
import duplicateIcon from "../images/icons/duplicate.svg";
import { pdfFilesStorage } from "../store/pdf-files";
import { chapterStorage } from "../store/chapters";
import { getCopies, makeCopy } from "../ethereum";
import CopyPopup from "./CopyPopup";

const ChapterPopup = (props) => {
  const { dispatch, state } = useContext(Store);
  const [copyOpen, setCopyOpen] = useState(false);
  const [copies, setCopies] = useState(0);

  const allowedCopyAmount = 2;

  useEffect(() => {
    (async () => {
      const copies = await getCopies(
        state.wallet.signer,
        state.wallet.address,
        props.tokenId
      );
      setCopies(copies.toNumber());
    })();
  }, [state.wallet.address]);

  const flipCopy = () => {
    props.setSelected(props.tokenId);
    if (copyOpen) {
      // Close
      setCopyOpen(false);
      // Remove class from opened popup
      document.getElementById("popup-copy").classList.remove("_popup-open");
      // Remove class from HTML tag
      document.documentElement.classList.remove("_popup-copy");
    } else {
      // Open
      setCopyOpen(true);
      // Add class to selected popup
      setTimeout(() => {
        document.getElementById("popup-copy").classList.add("_popup-open");
      }, 0);
      // Remove class from HTML tag
      document.documentElement.classList.add("_popup-open");
      document.documentElement.classList.add("_popup-copy");
    }
  };

  function choosePdfFile() {
    return pdfFilesStorage[props.tokenId];
  }

  const runMakeCopy = async () => {
    document.documentElement.classList.add("_transaction");
    try {
      makeCopy(state.wallet.signer, props.tokenId).then(() => {
        document.documentElement.classList.remove("_transaction");
        const tokens = state.tokenData;
        const updatedTokens = tokens.map((token) => {
          if (token.tokenId === props.tokenId) {
            token.balance++;
          }
          return token;
        });
        updateTokenDataAction(dispatch, updatedTokens);
        setCopies(copies + 1);
        setTimeout(() => {
          flipCopy();
        }, 0);
      });
    } catch (error) {
      document.documentElement.classList.remove("_transaction");
    }
  };

  return (
    <>
      {copyOpen && (
        <CopyPopup
          tokenId={props.tokenId}
          address={state.wallet.address}
          flipCopy={flipCopy}
          makeCopy={runMakeCopy}
          copies={copies}
          allowedCopyAmount={allowedCopyAmount}
          image={
            state.tokenData.find((token) => token.tokenId === props.tokenId)
              .image
          }
        />
      )}
      <div id="popup-chapter" className="popup">
        <div className="popup__wrapper">
          <div className="popup__content">
            <div className="chapter-popup">
              <div className="chapter-popup__body">
                <div className="chapter-popup__title">{props.chapter}</div>
                <div className="chapter-popup__text">
                  {chapterStorage[props.tokenId].map((paragraph) => (
                    <p>{paragraph}</p>
                  ))}
                </div>
              </div>
            </div>
            <div className="popup-actions">
              <div className="popup-actions__buttons">
                {/* Close button */}
                <button
                  className="popup-actions__button"
                  onClick={() => props.flipChapter(props.tokenId)}
                >
                  <img src={crossIcon} alt="cross icon" />
                </button>
                {/* Download button */}
                <a
                  href={choosePdfFile()}
                  download
                  className="popup-actions__button"
                >
                  <img src={downloadIcon} alt="download icon" />
                </a>
                {/* Flip copy button */}
                <button
                  className="popup-actions__button popup-actions__button_shadows"
                  onClick={() => flipCopy(props.tokenId)}
                >
                  <div className="popup-actions__quantity">
                    <span>{copies}</span>/<span>{allowedCopyAmount}</span>
                  </div>
                  <img src={duplicateIcon} alt="duplicate icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChapterPopup;
