import { WagmiConfig, createClient } from "wagmi";
import { getDefaultProvider } from "ethers";
import "./App.scss";
import Intro from "./components/Intro";
import Tiles from "./components/Tiles";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Preloader from "./components/Preloader";

import "./components/ParallaxMouse.js";
import "./components/Ui.js";

const client = createClient({
  autoConnect: true,
  provider: getDefaultProvider(),
});

const App = () => {
	
  return (
	
    <WagmiConfig client={client}>
		<Preloader />
      <div data-fullscreen className="wrapper">
        <Header />
        <div className="page">
          <Intro />
        </div>
        <Footer />
      </div>
      <Tiles />
    </WagmiConfig>
	 
  );
};

export default App;
