import image_1 from "../images/tiles/01.jpg";
import image_2 from "../images/tiles/02.jpg";
import image_3 from "../images/tiles/03.jpg";
import image_4 from "../images/tiles/04.jpg";

import marqueeImage_1 from "../images/covers/01.webp";
import marqueeImage_2 from "../images/covers/02.webp";
import marqueeImage_3 from "../images/covers/03.webp";
import marqueeImage_4 from "../images/covers/04.webp";


const Intro = () => {
	
	function openTiles() {
		const tilesPopup = document.getElementById("popup-tiles");
		tilesPopup.classList.add("_popup-open");
		document.documentElement.classList.add("_popup-open-purple");
	}

	const altImageText = 'background marquee picture';
	
	// Open tiles-popup by scroll down
	window.addEventListener("wheel", event => event.deltaY >= 150 && !document.documentElement.classList.contains('_popup-open-purple') ? openTiles() : null);

	return (
		<>

			<div className="page__marquee marquee">
				<div className="marquee__body">
					<div className="marquee__items">
						<div className="marquee__item">
							<div className="marquee__image">
								<img src={marqueeImage_1} alt={altImageText} />
							</div>
						</div>
						<div className="marquee__item">
							<div className="marquee__image">
								<img src={marqueeImage_2} alt={altImageText} />
							</div>
						</div>
						<div className="marquee__item">
							<div className="marquee__image">
								<img src={marqueeImage_3} alt={altImageText} />
							</div>
						</div>
						<div className="marquee__item">
							<div className="marquee__image">
								<img src={marqueeImage_4} alt={altImageText} />
							</div>
						</div>
					</div>
					<div className="marquee__items">
						<div className="marquee__item">
							<div className="marquee__image">
								<img src={marqueeImage_1} alt={altImageText} />
							</div>
						</div>
						<div className="marquee__item">
							<div className="marquee__image">
								<img src={marqueeImage_2} alt={altImageText} />
							</div>
						</div>
						<div className="marquee__item">
							<div className="marquee__image">
								<img src={marqueeImage_3} alt={altImageText} />
							</div>
						</div>
						<div className="marquee__item">
							<div className="marquee__image">
								<img src={marqueeImage_4} alt={altImageText} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="page__images main-images">
				<button className="main-images__items" onClick={() => openTiles()}>
					<div data-prlx-mouse className="main-images__item-ibg">
						<img src={image_1} alt="algo token" />
					</div>
					<div data-prlx-mouse className="main-images__item-ibg">
						<img src={image_2} alt="algo token" />
					</div>
					<div data-prlx-mouse className="main-images__item-ibg">
						<img src={image_3} alt="algo token" />
					</div>
					<div data-prlx-mouse className="main-images__item-ibg">
						<img src={image_4} alt="algo token" />
					</div>
				</button>
			</div>
		</>
	);
};

export default Intro;
