import crossIcon from "../images/icons/cross.svg";

const CopyPopup = (props) => {
  function checkRemains() {
    return props.copies < props.allowedCopyAmount ? true : false;
  }

  const CopyButton = () => {
    return (
      <button
        onClick={() => (checkRemains() ? props.makeCopy() : props.flipCopy(props.tokenId))}
        type="button"
        className="duplicate__button button button_border"
      >
        <span>{checkRemains() ? "Make copy" : "Ok"}</span>
      </button>
    );
  };

  const CopyText = () => {
    const CopiesRemains = () => {
      return (
        <div className="duplicate__text">
          You can copy this NFT <strong>{props.allowedCopyAmount - Math.round(props.copies)} more times</strong>
        </div>
      );
    };
    const CopiesFull = () => {
      return <div className="duplicate__text">You already have all copies!</div>;
    };
    // (props.allowedCopyAmount - Math.round(props.copies)
    return checkRemains() ? <CopiesRemains /> : <CopiesFull />;
  };

  return (
    <div id="popup-copy" className="popup">
      <div className="popup__wrapper">
        <div className="popup__content">
          <div className="duplicate">
            <div className="duplicate__image-ibg duplicate__image-ibg_contain">
              <img src={props.image} alt="book image" />
            </div>
            <div className="duplicate__title">NFT Chapter {props.tokenId}</div>
            <CopyText />
            <CopyButton />
          </div>
          <div className="popup-actions">
            <div className="popup-actions__buttons">
              <button
                className="popup-actions__button"
                onClick={() => props.flipCopy(props.tokenId)}
              >
                <img src={crossIcon} alt="cross icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyPopup;
