import eyeIcon from '../images/icons/eye.svg';
import openseaIcon from '../images/icons/opensea.svg';

const Tile = (props) => {
	const ZeroBalance = () => {
		return (
			<a href={props.url} target='_blank'  className='token-tiles__card token-card'>
				<div className='token-card__image-ibg'>
					<img src={props.img} alt={props.title} />
				</div>
				<div className='token-card__info'>
					<div className='token-card__read'>
						<img src={openseaIcon} alt='opensea icon' />
					</div>
				</div>
				<div className='token-card__content'>
					<div className='token-card__chart'>{props.chapter}</div>
					<h3 className='token-card__title'>{props.title}</h3>
				</div>
			</a>
		);
	};

	const GotSomeBalance = () => {
		return (
			<button
				type='button'
				className='token-tiles__card token-card token-card_available'
				onClick={() => props.flipChapter(props.tokenId)}
			>
				<div className='token-card__image-ibg'>
					<img src={props.img} alt={props.title} />
				</div>
				<div className='token-card__info'>
					<div className='token-card__read'>
						<img src={eyeIcon} alt='eye icon' />
					</div>
					<div className='token-card__balance'>{props.balance}</div>
				</div>
				<div className='token-card__content'>
					<div className='token-card__chart'>{props.chapter}</div>
					<h3 className='token-card__title'>{props.title}</h3>
				</div>
			</button>
		);
	};

	return <>{props.balance === 0 ? ZeroBalance() : GotSomeBalance()}</>;
};

export default Tile;
